.editor {
  // disable the :focus outline for now, though it's bad practice :(
  outline: 0;
  user-select: none !important;
  height: 100%;
}

// This is rendered by the <Editor/> but not wrapped inside it, to we can't wrap it here within `.editor { ... }`
.loading.modal {
  .modal-content {
    background: transparent;
    border: none;
  }
}

.editor {
  .app-meta-menu {
    align-items: center;
    position: fixed;
    right: 1rem;
    top: 1rem;
    display: flex;
  }

  .workspace-history {
    position: absolute;
    left: 1rem;
    top: 1rem;
    z-index: 1;
  }

  &.mobile {
    flex-direction: column !important;

    .spread-shadow.spread-outline {
      outline: solid 3px $primary;
      outline-offset: 3px;
    }
  }

  .order-button {
    .price-label {
      font-size: 0.75em;
    }
  }
}

.svg-defs {
  width: 0;
  height: 0;

  position: fixed;
  top: 0;
  left: 0;
}

.desktop-modal {
  .modal-dialog {
    max-width: calc(min(600px, 100vw));
  }
}

.notification-snackbar {
  z-index: 100;
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  top: 4rem;
  bottom: auto;
  max-width: 80%;
  width: fit-content;

  padding: 0.5rem;
  border-radius: 4px;
  box-shadow: 0 0.5rem 1rem rgba(27, 28, 29, 0.15);

  background: #fff;
  text-align: center;
  font-size: 0.9rem;

  &__close-button {
    margin-bottom: 2px;

    svg {
      display: flex;
      width: 20px;
      height: 20px;
    }
  }

  @media (min-width: $breakpoint-desktop) {
    top: auto;
    bottom: 2rem;
    width: 400px;
  }
}

.pulse {
  animation: pulse 2s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }

}