$sticker-container-width: 78px;
$sticker-container-height: $sticker-container-width * 1.4;
$light-gray: #c9c9c9;
$mobile-footer-menu-height: 86px;
$sidebar-width: 495px;

.sidebar {
  background: white;
  display: flex;
  position: relative;
  width: $sidebar-width;
  z-index: 11;

  .sidebar-nav {
    width: auto;

    @media (min-width: $breakpoint-desktop) {
      padding-bottom: 75px;
    }
  }

  .logo {
    color: $dark;
  }

  .tab-marker {
    position: absolute;
    left: 0;
    top: 0;
    background: darken($light, 3%);
    z-index: -1;
  }

  .nav-link {
    svg {
      width: 25px;
      height: 25px;

      path,
      rect {
        fill: $primary;
      }
    }
  }

  .nav-link:not(.active) {
    color: $black;

    svg {
      path,
      rect {
        fill: $black;
      }
    }
  }

  .tab-content {
    z-index: 1;

    > .active {
      display: flex;
      flex: 1;
    }
  }

  .sidebar-minimize {
    position: absolute;
    left: 100%;
    top: calc(50% - 10px);
    margin-left: -0.8rem;
    border: 0;
    padding: 4px 0 4px 8px;

    &:focus {
      box-shadow: none !important;
    }
  }

  &.minimized {
    box-shadow: none !important;
    width: auto;

    .tab-content {
      display: none !important;
    }

    .sidebar-minimize {
      display: none;
    }
  }
}

.sidebar-menu {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .items-count {
    text-align: center;
    font-size: 0.9rem;
  }
}

.section-panel-menu {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .stickers-count {
    text-align: center;
    font-weight: bold;
    font-size: 0.9rem;
  }
}

.edit-section-title:focus,
.new-section-title:focus {
  outline: none;
}

.sticker-container {
  padding: 0.5rem;
  width: $sticker-container-width;
  height: $sticker-container-height;
  cursor: pointer;
  background: white;

  &.double {
    width: $sticker-container-width * 2;
  }

  .sticker-image {
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .no-image {
      background-image: linear-gradient(#b3b3b3, $light-gray);

      span {
        font-weight: bold;
        font-size: 3rem;
        opacity: 0.5;
      }
    }
  }

  .sticker-info-container {
    font-family: $fontName, sans-serif;
    height: 23px;

    .sticker-info {
      font-size: 9px;
      padding-top: 3px;
    }

    .sticker-name,
    .sticker-position {
      white-space: nowrap;
      overflow: hidden;
      font-weight: 700;
      margin-top: -1px;
    }

    .sticker-position {
      font-weight: 100;
    }

    .sticker-number {
      font-size: 0.6rem;
      overflow: hidden;
    }
  }
}

.album-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sync-status-indicator {
    margin-left: 1rem;
    color: lightgrey;
    transition: color 0.3s ease;
  }

  .sync-status-indicator.syncing {
    color: black;
  }

  .pencil {
    opacity: 0.5;
  }
}

.images-grid {
  width: 100%;
  padding: 0 0.5rem 0.5rem 0.5rem;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 100px;
  grid-gap: 4px;
  grid-auto-flow: dense;

  .pexels-result {
    cursor: grab;
  }

  .landscape {
    grid-column-end: span 2;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.image-item.active {
  padding: 2px;
  border: 2px solid $primary;
}

.sticker-container.active {
  border: 2px solid $primary;
}

.dnd-hover {
  opacity: 0.5;
}

.spread-preview {
  cursor: grab;
}

.sidebar-tab-icon {
  position: relative;

  .badge {
    position: absolute;
    top: -10px;
    right: 20px;

    @media (min-width: $breakpoint-desktop) {
      right: 10px;
    }
  }

  .emoji {
    font-size: 1.5rem;
    text-decoration: none !important;
  }
}

.sidebar-menu-button {
  background-color: #fff !important;
  border: 0 !important;

  &:hover,
  &:focus {
    background-color: $light !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.unplaced-stickers {
  .sticker-placement-help {
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover {
    .sticker-placement-help {
      opacity: 1;
    }
  }
}

.sticker-image-loading-spinner {
  left: 0;
  top: 0;
}

.sidebar-sticker {
  position: relative;

  .icon {
    z-index: 2;
    position: absolute;
    top: -7px;
    width: 20px;
    height: 20px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.sticker-uploads-modal .sidebar-sticker .icon {
  top: -3px;
  width: 30px;
  height: 30px;
}

.sidebar-panel {
  width: 100%;

  @media (min-width: $breakpoint-desktop) {
    width: auto;
  }
}

.review-panel {
  .comment-card {
    height: 140px;
  }
}

.mobile-sidebar {
  background: #fff;
  position: fixed;

  // Leave room for fixed-height tab nav
  bottom: $mobile-footer-menu-height;
  height: calc(90% - $mobile-footer-menu-height);

  // Leave a tiny margin on either side
  left: 1px;
  width: calc(100% - 2px);

  border-radius: 16px 16px 0 0;

  transition: all 300ms ease;

  &-content {
    overflow: scroll;

    // Hide scrollbar across browsers
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.open-tabnav-btn {
  width: 56px;
  height: 56px;
  position: fixed;
  bottom: 20px;
  right: 16px;
  z-index: 2;
  border-radius: 50%;

  .icon {
    pointer-events: none;
    svg {
      width: 21px;
      height: 21px;
    }
  }
}

.footer-menu-close-btn {
  width: 32px;
  height: 32px;
  position: fixed;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    pointer-events: none;

    svg {
      width: 21px;
      height: 21px;
    }
  }
}

.mobile-footer-menu {
  background: $light;
  border-top: 1px solid #eeeeee;
  transition: transform 300ms ease, opacity 300ms ease;
  z-index: 2;
  padding: 0.625rem 1rem 1rem 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $mobile-footer-menu-height;
  z-index: 3;
}

.mobile-tabnav-tabs {
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  overflow-x: auto;
  gap: 12px;
  padding-top: 0.625rem;

  &__tab {
    color: $black;
    flex: 1 0 70px;
    text-decoration: none !important;
  }

  &__scroll-btn {
    background: $light;
    height: 100%;
    padding: 3px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }

  svg {
    width: 30px;
    height: 30px;

    path {
      fill: $black;
    }
  }

  .active {
    color: $primary;

    .icon {
      animation: none;
    }

    path {
      fill: $primary;
    }
  }
}

$icon-position: -8px;

.onboarding-panel {
  padding-right: -$icon-position;
}

.onboarding-step {
  color: initial;
  font-size: 0.8rem;

  &:hover {
    text-decoration: none;
  }

  &-headline-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 25px;
    height: 25px;
    right: $icon-position;
    top: $icon-position;

    border-radius: 50%;
    color: #fff;

    &.active {
      background-color: $primary;
    }

    &.done {
      background-color: $success;
    }

    &.todo {
      background-color: $secondary;
    }
  }

  &-headline {
    font-size: 14px;
    font-weight: 500;
  }

  .card {
    transition: background-color 0.25s ease, color 0.25s ease,
      border-color 0.25s ease;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid rgba(27, 28, 29, 0.125);
      color: #333;
    }

    &.active {
      background-color: #fff !important;
      color: $primary;
      border-color: $primary;

      &:hover {
        background-color: $primary !important;
        color: #fff !important;
      }
    }
  }
}

.album-release-date-input {
  min-width: 270px; // Make sure the label is visible
}
