.view-control-menu {
  position: fixed;
  bottom: 1.875rem;
  left: 50%;
  transform: translate(-50%, 0);
  transition: all 300ms ease;

  @media (min-width: $breakpoint-desktop) {
    right: 1rem;
    bottom: 1rem;
    left: unset;
    transform: none;
  }

  .page-control {
    .btn {
      &,&.active,&:active,&:focus {
        border-color: #fff !important;
        background: #fff!important;
        color: #000!important;
        outline: none !important;
        box-shadow: none !important;
        padding: 0.375rem;
      }
    }
    .page {
      width: 4.5rem;
      border-color: #fff !important;
      text-align: center;
      &,&.active,&:active,&:focus {
        box-shadow: none !important;
      }
    }

  }

  &--moveUp {
    bottom: 7.25rem;
  }

}
