.workspace {
  display: flex;
  position: relative;
  height: 100vh;
  flex-grow: 1;

  &.mobile {
    flex: 1 1 auto;
  }
}
