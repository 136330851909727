.notifications {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 2;
}

.notifications > .toast {
  min-width: 20rem;
}
