.sticker-form-popup {
    max-height: 100%;
    overflow: auto;

    @media (min-width: $breakpoint-desktop) {
        max-width: 310px;
    }
}

.sticker-form {
    left: 0;
    z-index: 1;
    width: 100%;

    .face-fit {
      cursor: pointer;

      &.disabled {
        cursor: default;
        opacity: 0.2;
      }
    }

    .custom-range {
        margin-top: 0.2em;
    }
  
    svg.sticker-preview {
        background: #fff;
        margin: 0.5em 1em;
        cursor: move;
        touch-action: none;

        image {
            pointer-events: none;
        }
    }

    .control-group-scale {
        display: flex;
        flex-direction: row;   
    }

    .icon-move {
        display: block;
        width: 30px;
        height: 30px;
        background: url(images/sticker_move.svg) no-repeat;
        background-size: contain;
        position: absolute;
        left: 50px;
        top: 30px;
        pointer-events: none;
    }

    .upload-icon {
        display: inline-block;
        margin-right: 0.1em;
        svg {
            width: 18px;
            height: 18px;
            margin-top: -3px;            
        }
    }

    .sticker-layout-item {
        border-radius: $border-radius;
        cursor: pointer;
        display: inline-block;
        padding: 7px;

        .sticker-template-view {
            height: 70px;
        }

        &.active {
            background-color: #004c973d;
        }
    }
}

.sticker-form-mobile {
    flex-direction: column;
    position: fixed;
    display: flex;
    top: 0;
    left: 0%;
    right: 0;
    height: 100vh;
    background: white;
    padding: 1rem;
    overflow: auto;
    z-index: 2;

    .sticker-form {
        margin: auto;
        width: 100% !important;
    }

    .sticker-form-close-button {
        svg {
            height: 32px;
            width: 32px;
        }
    }
}
